import React, { useEffect, useRef } from 'react';
import s from './Line2.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"


export const Line2 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: '0%',
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: '100%',
        ease: 'linear',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: '-=100px',
        },
      }
    );
  },[])

  return (
    <svg className={s.line} xmlns="http://www.w3.org/2000/svg" width="1440" height="452" viewBox="0 0 1440 452" fill="none">
      <path ref={lineRef} opacity="0.5" d="M-532.5 1H510C620.457 1 710 90.5431 710 201V251C710 361.457 799.543 451 910 451H1941" stroke="#1B52AD" stroke-width="2"/>
    </svg>
  );
};
