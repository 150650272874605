import React, { useEffect, useRef } from 'react';
import s from './Line6.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"


export const Line6 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: '0%',
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: '100%',
        ease: 'linear',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: '-=300px',
        },
      }
    );
  },[])

  return (
    <svg className={s.line} xmlns="http://www.w3.org/2000/svg" width="793" height="1272" viewBox="0 0 793 1272" fill="none">
      <path ref={lineRef} opacity="0.5" d="M451 795H656.517C731.075 795 791.517 855.442 791.517 930M792 930V1135.52C792 1210.08 731.558 1270.52 657 1270.52M451.02 795H245.503C170.945 795 110.503 734.558 110.503 660M110.5 0V660M-104 1270.5H657" stroke="#1B52AD" stroke-width="2"/>
    </svg>
  );
};
